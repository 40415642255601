import { useEffect, lazy, Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import '../../utils/locale/i18n';
import { usePreviousProps } from '../../hooks/usePreviousProps';
import { useTypedSelector } from '../../hooks/useTypedSelector';

import Spinner from '../components/Spinner';

import { retriveItem } from '../../utils/local-storage';
import { useActions } from '../../redux-manager/cache/auth-info/hooks/useActions';

const HomePageContainer = lazy(() => import('../module/public/home'));
const LanguageContainer = lazy(() => import('../module/protected/language'));
const EmailVerificationContainer = lazy(() =>
  import('../module/protected/email-verification')
);
const LoginPageContainer = lazy(() => import('../module/public/login'));
const RegisterPageContainer = lazy(() => import('../module/public/register'));
const TermsAndConditionsContainer = lazy(() =>
  import('../module/public/terms-and-conditions')
);
const AboutUsContainer = lazy(() => import('../module/public/about-us'));
const ContactUsContainer = lazy(() => import('../module/public/contact-us'));
// const ConsultationContainer = lazy(() => import('../module/public/consultation/index.js'));
const CommunityContainer = lazy(() => import('../module/public/community/index.jsx'));
const LunchAndLearnContainer = lazy(() => import('../module/public/lunch-and-learn/index.jsx'));
const LunchAndLearnAllContainer = lazy(() => import('../module/public/lunch-and-learn-all/index.jsx'));
const RespectVictoriaContainer = lazy(() => import('../module/public/respect-victoria/index.jsx'));
const PolaronRebrandingContainer = lazy(() => import('../module/public/polaron-rebranding/index.jsx'));
const PPWorkingWithInterpretersContainer = lazy(() => import('../module/public/pp-working-with-interpreters/index.jsx'));
const LungFoundationAustraliaContainer = lazy(() => import('../module/public/lung-foundation-australia/index.jsx'));
const ThankYouContainer = lazy(() => import('../module/public/thank-you'));
const PageNotFoundContainer = lazy(() =>
  import('../module/public/page-not-found')
);
const HomeLayout = lazy(() => import('../layout/home-layout'));
const AdminLayout = lazy(() =>
  import('../layout/dashboard-layout/admin-layout')
);
const ClientLayout = lazy(() =>
  import('../layout/dashboard-layout/client-layout')
);
const TranslatorLayout = lazy(() =>
  import('../layout/dashboard-layout/translator-layout')
);
const FeedbackPageContainer = lazy(() =>
  import('../module/protected/dashboard/admin/feedback')
);
const LanguagePageContainer = lazy(() =>
  import('../module/protected/dashboard/admin/language')
);
const ManageLanguagePageContainer = lazy(() =>
  import('../module/protected/dashboard/admin/language/manage')
);
const EntryFeedbackPageContainer = lazy(() =>
  import('../module/protected/entry-feedback')
);
const DashboardPageContainer = lazy(() =>
  import('../module/protected/dashboard/admin/home')
);
const TranslatorDashboardPageContainer = lazy(() =>
  import('../module/protected/dashboard/translator/home')
);

const ClientDashboardPageContainer = lazy(() =>
  import('../module/protected/dashboard/client/home/index.js')
);

const ClientFeedbackContainer = lazy(() =>
  import('../module/protected/dashboard/client/feedback')
);

const ClientFeedbackDetailPageContainer = lazy(() =>
  import('../module/protected/dashboard/client/feedback/detail-page')
);

const ClientDocumentationContainer = lazy(() =>
  import('../module/protected/dashboard/client/documentation')
);

const ClientSurveyResponsesContainer = lazy(() =>
  import('../module/protected/dashboard/client/SurveyResponses')
);

const ClientSurveyAnalyticsContainer = lazy(() =>
  import('../module/protected/dashboard/client/SurveyAnalytics')
);

const SettingsLayout = lazy(() =>
  import('../layout/dashboard-layout/settings-layout')
);
const GeneralSettingsContainer = lazy(() =>
  import('../module/protected/dashboard/admin/settings/general')
);
const TranslatorGeneralSettingsContainer = lazy(() =>
  import('../module/protected/dashboard/translator/settings/general')
);
const NotificationContainer = lazy(() =>
  import('../module/protected/dashboard/admin/settings/notification')
);
const PlanAndBillingContainer = lazy(() =>
  import('../module/protected/dashboard/admin/settings/plan-and-billing')
);
const SecurityContainer = lazy(() =>
  import('../module/protected/dashboard/admin/settings/password')
);
const TranslatorFeedbackContainer = lazy(() =>
  import('../module/protected/dashboard/translator/feedback')
);
const TranslatorFeedbackDetailPageContainer = lazy(() =>
  import('../module/protected/dashboard/translator/feedback/detail-page')
);
const TranslatorNotificationPageContainer = lazy(() =>
  import('../module/protected/dashboard/translator/notifications')
);
const AdminFeedbackDetailPageContainer = lazy(() =>
  import('../module/protected/dashboard/admin/feedback/detail-page')
);
const AdminTranslatorContainer = lazy(() =>
  import('../module/protected/dashboard/admin/translator')
);
const AdminClientContainer = lazy(() =>
  import('../module/protected/dashboard/admin/client')
);
const AdminNotificationContainer = lazy(() =>
  import('../module/protected/dashboard/admin/notifications')
);
const TranslatorDetailPageAdmindashboardContainer = lazy(() =>
  import('../module/protected/dashboard/admin/translator/detail-page')
);
const SurveyResponsesContainer = lazy(() =>
  import('../module/protected/dashboard/admin/SurveyResponses')
);
const SurveyAnalyticsContainer = lazy(() =>
  import('../module/protected/dashboard/admin/SurveyAnalytics')
);

export interface IAppRouterProps {}

const AppRouter: React.FC<IAppRouterProps> = props => {
  const location = useLocation();
  const { saveUserInfo } = useActions();

  const {
    locale: { localeCode }
  } = useTypedSelector(state => state);
  const prevProps = usePreviousProps({
    localeCode
  });

  useEffect(() => {
    if (localeCode && localeCode !== prevProps?.localeCode) {
      i18n.changeLanguage(localeCode);
      return;
    }
  }, [localeCode]);

  useEffect(() => {
    _retrieveDataFromStorage();
  }, []);

  const _retrieveDataFromStorage = (): void => {
    let stringifyData = retriveItem('@userinfo');
    saveUserInfo(JSON.parse(stringifyData));
    return;
  };

  return (
    <Suspense
      fallback={
        <div>
          <Spinner size="large" color="indigo" />
        </div>
      }
    >
      <AnimatePresence exitBeforeEnter>
        <Routes key={location.pathname} location={location}>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<HomePageContainer />} />
            <Route path="/login" element={<LoginPageContainer />} />
            <Route path="/register" element={<RegisterPageContainer />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsContainer />}
            />
            <Route path="/about-us" element={<AboutUsContainer />} />
            <Route path="/contact-us" element={<ContactUsContainer />} />
            {/* <Route path="/consultation" element={<ConsultationContainer />} /> */}
            <Route path="/ppv" element={<CommunityContainer />} />
            <Route path="/lunchandlearn" element={<LunchAndLearnContainer />} />
            <Route path="/lunchandlearn-all" element={<LunchAndLearnAllContainer />} />
            <Route path="/respect-victoria" element={<RespectVictoriaContainer />} />
            <Route path="/polaron-rebranding" element={<PolaronRebrandingContainer />} />
            <Route path="/pp-working-with-interpreters" element={<PPWorkingWithInterpretersContainer />} />
            <Route path="/lfa" element={<LungFoundationAustraliaContainer />} />
            <Route path="/thank-you" element={<ThankYouContainer />} />
          </Route>

          <Route path="/" element={<HomeLayout />}>
            <Route path="lang" element={<LanguageContainer />} />
            <Route
              path="entry-feedback"
              element={<EntryFeedbackPageContainer />}
            />
          </Route>

          <Route path="/email-verification">
            <Route
              path=":userId/:token"
              element={<EmailVerificationContainer />}
            />
          </Route>

          <Route path="/dashboard" element={<AdminLayout />}>
            <Route index element={<DashboardPageContainer />} />
            <Route path="feedback">
              <Route index element={<FeedbackPageContainer />} />
              <Route
                path=":feedbackId"
                element={<AdminFeedbackDetailPageContainer />}
              />
            </Route>
            <Route path="language">
              <Route index element={<LanguagePageContainer />} />
              <Route
                path=":languageId"
                element={<ManageLanguagePageContainer />}
              />
              {/* <Route
                path=":feedbackId"
                element={<AdminFeedbackDetailPageContainer />}
              /> */}
            </Route>
            <Route path="translators">
              <Route index element={<AdminTranslatorContainer />} />
              <Route
                path=":translatorId"
                element={<TranslatorDetailPageAdmindashboardContainer />}
              />
            </Route>
            <Route path="clients">
              <Route index element={<AdminClientContainer />} />
            </Route>
            <Route path="notifications">
              <Route index element={<AdminNotificationContainer />} />
            </Route>
            <Route path="settings" element={<SettingsLayout />}>
              <Route index element={<GeneralSettingsContainer />} />
              <Route path="notification" element={<NotificationContainer />} />
              <Route path="security" element={<SecurityContainer />} />
              <Route
                path="plan-and-billings"
                element={<PlanAndBillingContainer />}
              />
            </Route>
            <Route path="survey-analytics">
              <Route index element={<SurveyAnalyticsContainer />} />
            </Route>
            <Route path="surveys">
              <Route index element={<SurveyResponsesContainer/>} />
              {/* <Route
                path=":surveyId"
                element={<SurveyResponseDetailContainer/>}
              /> */}
            </Route>
          </Route>

          <Route path="/client-dashboard" element={<ClientLayout />}>
            <Route index element={<ClientDashboardPageContainer />} />
            <Route path="feedback">
              <Route index element={<ClientFeedbackContainer />} />
              <Route
                path=":feedbackId"
                element={<ClientFeedbackDetailPageContainer />}
              />
            </Route>
            <Route path="surveys">
              <Route index element={<ClientSurveyResponsesContainer />} />
            </Route>
            <Route path="survey-analytics">
              <Route index element={<ClientSurveyAnalyticsContainer />} />
            </Route>
            <Route path="documentation" element={<ClientDocumentationContainer />}></Route>
          </Route>

          <Route path="/translator-dashboard" element={<TranslatorLayout />}>
            <Route index element={<TranslatorDashboardPageContainer />} />
            <Route path="feedback">
              <Route index element={<TranslatorFeedbackContainer />} />
              <Route
                path=":feedbackId"
                element={<TranslatorFeedbackDetailPageContainer />}
              />
            </Route>
            <Route
              path="notifications"
              element={<TranslatorNotificationPageContainer />}
            />

            <Route path="settings" element={<SettingsLayout />}>
              <Route index element={<TranslatorGeneralSettingsContainer />} />
              <Route path="notification" element={<NotificationContainer />} />
              <Route path="security" element={<SecurityContainer />} />
              <Route
                path="plan-and-billings"
                element={<PlanAndBillingContainer />}
              />
            </Route>
          </Route>

          <Route path="*" element={<PageNotFoundContainer />} />
        </Routes>
      </AnimatePresence>
    </Suspense>
  );
};

export default AppRouter;
